import React, { createContext, useCallback, useLayoutEffect, useState } from 'react'
import jwt_decode from "jwt-decode"
import { ErrorHandling, isEmpty, isJson } from '@src/helpers/GlobalFunctions';
import { adminRoutes, candidateRoutes, employerRoutes, publicRoutes } from '@src/routes/routes';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { GetUserDetails } from '@src/api/users';
import { CheckSitePassword } from '@src/api/other';

const removeUserStorage = () => {
    localStorage.removeItem("Token")
    localStorage.removeItem("RefreshToken")
    localStorage.removeItem("_expiredTime")
}

const handleLogoutOnError = (error = "", ErrorType = "") => {
    removeUserStorage()
    window.location.replace(`/?error=${error}&type=${ErrorType}`)
}

const AuthProvider = ({ children }) => {

    const [loading, setLoading] = useState(true);

    const [role, setRole] = useState();
    const [routes, setRoutes] = useState([]);

    const [isLogin, setLoginStatus] = useState();
    const [isLogout, setLogout] = useState();


    const [userToken, setToken] = useState()
    const [user, setUser] = useState()


    const tokenExpired = useCallback(() => {

        const MySwal = withReactContent(Swal)
        setTimeout(() => {
            MySwal.fire({
                title: "Session Expired",
                text: "Your login token has expired. Please login again thank you.",
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: "Sign In Again",
                customClass: {
                    confirmButton: "btn btn-info mx-2",
                    cancelButton: "btn btn-light mx-2",
                },
                buttonsStyling: false,
            })
                .then(() => {
                    removeUserStorage()
                    window.location.replace('/sign-in')
                })
                .finally(() => setLoading(false))
        }, 300)

    }, [])

    const [is_allowed, setAllowed] = useState(false);

    useLayoutEffect(() => {

        const URL = window.location.host;
        if (!URL.includes('dev.craftpositions')) {
            setAllowed(true)
            return;
        }

        const MySwal = withReactContent(Swal)
        Promise.resolve(
            MySwal.fire({
                title: "Password Required!",
                input: "password",
                inputLabel: "Enter the password to access the website",
                buttonsStyling: false,
                customClass: {
                    input: 'mx-5 form-control form-control-lg mt-2 mb-4 w-auto',
                    inputLabel: 'justify-content-start mx-5 mt-4',
                    confirmButton: 'btn btn-primary btn-lg'
                },
                inputPlaceholder: "Enter your password",
                allowOutsideClick: false,
                inputAttributes: {
                    autocapitalize: "off",
                    "aria-label": "Enter the password to access the website",
                    autocorrect: "off"
                },
                inputValidator: (value) => {
                    if (!value) {
                        return "You need to enter a password to access this website!";
                    }

                    if (value !== process.env.REACT_APP_PW) {
                        return "Incorrect password!"
                    }
                }
            })
        ).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    return CheckSitePassword(result.value)
                }

                throw new Error('Unable to get user input')
            }
        })
            .then((response) => {
                if (response && response.data && response.data.is_valid) {
                    setAllowed(Boolean(response.data.is_valid))
                } else {
                    setAllowed(false)
                }
            })

    }, [])

    useLayoutEffect(() => {

        if (is_allowed) {

            let status = false
            let token = localStorage.getItem("Token")

            if (token) {

                setLoading(true)
                Promise.resolve()
                    .then(() => {
                        let decoded = jwt_decode(token)
                        if (isJson(decoded) && !isEmpty(decoded)) {
                            if (new Date(decoded?.exp * 1000) > new Date()) {
                                status = true
                                if (decoded.is_admin === true) {
                                    setRoutes(adminRoutes)
                                    setRole('admin')
                                } else if (decoded.role === "candidate") {
                                    setRole('candidate')
                                    setRoutes(candidateRoutes)
                                } else if (decoded.role === "employer") {
                                    setRole('employer')
                                    setRoutes(employerRoutes)
                                } else {
                                    throw new Error("no valid user found. Signing out...")
                                }

                                setLogout(null)
                                setLoginStatus(status)
                                setToken({ ...decoded, token });

                            } else {
                                throw new Error("token-expired", "token")
                            }
                        } else {
                            throw new Error("Invalid token found. Signing out...")
                        }
                    })
                    .then(() => GetUserDetails())
                    .then((result) => setUser(result.data))
                    .catch((error) => {
                        if (error === "token-expired") {
                            tokenExpired();
                        }

                        handleLogoutOnError(ErrorHandling(error), "token")
                    })
                    .finally(() => setLoading(false))
            } else {
                setLoginStatus(status)
                setRoutes(publicRoutes)
                setLoading(false)
            }
        }

    }, [tokenExpired, is_allowed])


    const Logout = useCallback(() => {

        localStorage.removeItem("Token")
        localStorage.removeItem("RefreshToken")


        setToken(null);
        setLogout(true);
        setLoginStatus(false);

        setRole(null);
        setLoading(false)
        setRoutes(publicRoutes)

    }, [])

    const updateUserInfo = useCallback((user_info) => {
        setUser((prev) => ({ ...prev, ...user_info }))
    }, [])


    const handleLogin = useCallback((status = null, token) => {
        if (status && token) {
            setLoading(true)
            Promise.resolve()
                .then(() => {
                    let decoded = jwt_decode(token)
                    if (decoded.is_admin === true) {
                        setRoutes(adminRoutes)
                        setRole('admin')
                    } else if (decoded.role === "candidate") {
                        setRole('candidate')
                        setRoutes(candidateRoutes)
                    } else if (decoded.role === "employer") {
                        setRole('employer')
                        setRoutes(employerRoutes)
                    } else {
                        throw new Error("no valid user found. Signing out...")
                    }

                    setLogout(null)
                    setLoginStatus(status)
                    setToken({ ...decoded, token });
                })
                .then(() => GetUserDetails())
                .then((result) => setUser(result.data))
                .catch((error) => handleLogoutOnError(ErrorHandling(error), "token"))
                .finally(() => setLoading(false))
        }
    }, [])

    return (

        <AuthContext.Provider
            value={{
                role,
                routes,
                isLogin: isLogin,
                hasLogout: isLogout,
                LoginData: userToken,
                userData: user,
                isLoading: loading,
                updateUserInfo,
                setLoginStatus: handleLogin,
                tokenExpired,
                Logout,
            }}
        > {is_allowed ? children : null} </AuthContext.Provider>
    )
}


export const AuthContext = createContext({})
export default AuthProvider