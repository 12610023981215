import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from './auth-user'
import { ErrorHandling, isJson } from '@src/helpers/GlobalFunctions';
import { GetOfflineConnections, GetOnlineConnections } from '@src/api/users';
import { toast } from '@src/components/ToastExtended';

const UserProvider = ({ children }) => {
    const { isLogin, hasLogout, userData } = useContext(AuthContext)

    const [online, setOnline] = useState(false);
    const onlineSocket = useMemo(() => {

        const token = localStorage.getItem("Token");
        const socket_url = process.env.REACT_APP_WEBSOCKET;

        if (token && socket_url) {
            if (isLogin && userData && !userData.is_away) {
                return new WebSocket(`${socket_url}/set-user-status/?token=${token}`);
            }
        }

        return null
    }, [isLogin, userData])

    const disconnectUserSocket = useMemo(() => {
        if ((userData && userData.is_away) || hasLogout) return true
        return false
    }, [hasLogout, userData])

    useEffect(() => {
        if (onlineSocket) {
            onlineSocket.onopen = function (e) {
                console.log("Connection established! (1)", e);
                setOnline(true);
            };

            onlineSocket.onclose = async function (e) {
                console.log('close (1)', e)
                setOnline(false);
            }
        }


        return () => {
            if (onlineSocket && onlineSocket.readyState === 1) {
                onlineSocket.close();
            }
        }
    }, [onlineSocket])

    useEffect(() => {
        if (disconnectUserSocket) {
            if (onlineSocket && onlineSocket.readyState === 1) {
                onlineSocket.close();
            }
        }
    }, [disconnectUserSocket, onlineSocket])


    const [socket, setSocket] = useState(null);
    const [serverMessage, setServerMessage] = useState("");
    const [webSocketReady, setWebSocketReady] = useState(false);

    const [connections, setConnections] = useState({
        online: [],
        offline: [],
    })


    useEffect(() => {
        const fetchConnectionsList = async () => {
            Promise.all([
                GetOnlineConnections(),
                GetOfflineConnections()
            ]).then((responses) => {
                const onlineList = responses[0];
                const offlineList = responses[1];
                setConnections(prev => {
                    const arr = [...onlineList.data, ...offlineList.data, ...prev.offline, ...prev.online]
                    prev.offline = arr.filter((value, index, self) =>
                        (index === self.findIndex((t) => (
                            (t.id === value.id)
                        ))) && value.online <= 0
                    )
                    prev.online = arr.filter((value, index, self) =>
                        (index === self.findIndex((t) => (
                            (t.id === value.id)
                        ))) && (value.online > 0 && !value.is_away)
                    )

                    return prev
                })
            })
                .catch((error) => {
                    toast.show({
                        type: "danger",
                        title: "Something went wrong",
                        description: ErrorHandling(error)
                    })
                })
        }

        const token = localStorage.getItem("Token");
        if (token && isLogin) {
            fetchConnectionsList().finally(() => {
                const socket_url = process.env.REACT_APP_WEBSOCKET
                setSocket(
                    new WebSocket(`${socket_url}/my-connected-users/?token=${token}`) //"wss://craft-position-backend-development.onrender.com/my-connected-users/?token=" + token
                )
            })
            return;
        }

    }, [isLogin])

    useEffect(() => {

        if (socket) {

            socket.onopen = function (e) {
                setWebSocketReady(true);
                console.log("Connection established! (2)", e);
            };

            socket.onclose = async function (e) {
                console.log('close (2)', e)
                setWebSocketReady(false);
            }

            socket.onmessage = async function (e) {
                console.log('message (2)', e)
                setServerMessage(e.data)
            }

            socket.onerror = function (err) {
                console.log('Socket encountered error: ', err.message, 'Closing socket');
                setWebSocketReady(false);
                socket.close();
            };

            if (hasLogout) {
                socket.close();
                setConnections({
                    online: [],
                    offline: [],
                })
            }
        }

        return () => {
            if (socket) {
                socket.close();
            }
        }
    }, [socket, hasLogout])

    useEffect(() => {
        if (serverMessage !== "" && isJson(serverMessage)) {
            const user = JSON.parse(serverMessage);
            const meUser = userData;
            if (user && meUser && user.id !== meUser.id) {
                setConnections(prev => {
                    const onlineList = prev.online.find((p) => p.id === user.id)
                    const offlineList = prev.offline.find((p) => p.id === user.id)

                    if (user.online > 0) {
                        if (!onlineList) prev.online.push(user);
                        if (offlineList) {
                            prev.offline = prev.offline.filter((off) => off.id !== user.id)
                        }
                    } else {
                        if (!offlineList) prev.offline.push(user);
                        if (onlineList) {
                            prev.online = prev.online.filter((oln) => oln.id !== user.id)
                        }
                    }

                    return prev
                })
            }
        }
    }, [serverMessage, userData])

    return (
        <UserContext.Provider
            value={{
                online,
                connections: connections,
                socketReady: webSocketReady,
            }}
        > {children} </UserContext.Provider>
    )
}

export const UserContext = createContext({})
export default UserProvider
