import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"

// import AuthProvider from "@src/context/auth"

import App from "./App"
import "./index.scss"
import AuthProvider from "./context/auth-user"
import UserProvider from "./context/online-user"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <HelmetProvider>
                <AuthProvider>
                    <UserProvider>
                        <App />
                    </UserProvider>
                </AuthProvider>
            </HelmetProvider>
        </BrowserRouter>
    </React.StrictMode>
)
