import { privateAxios, publicAxios } from "axios.config"

export const handleTermsAndConditions = async (request = "GET", text = "") => {
    const path = "/api/terms-and-conditions"
    if (request === "POST" && text !== "") {
        return await privateAxios.post(path, { text })
    } else {
        return await publicAxios.get(path)
    }
}

export const handlePrivacyStatement = async (request = "GET", text = "") => {
    const path = "/api/privacy-policy"
    if (request === "POST" && text !== "") {
        return await privateAxios.post(path, { text })
    } else {
        return await publicAxios.get(path)
    }
}

export const contactCraftPositions = async (values) => {
    return await publicAxios.post("/api/contact-us", values)
}

export const subscribeNewsletter = async (email = "") => {
    return await publicAxios.post("/api/subscriptions", { email })
}

export const sendNewsletter = async (values) => {
    return await privateAxios.post("/api/send-newsletter", values)
}

export const uploadFile = async (file = "") => {
    const formData = new FormData();
    formData.append("file", file)

    return await privateAxios.post("/api/upload", formData)
}

export const GetSkills = async () => {
    return await privateAxios.get(`/api/skill-suggestions`)
}

export const UpdateSkillsArray = async (skills) => {
    return await privateAxios.post("/api/skill-suggestions", skills)
}

export const CheckSitePassword = async (password = '') => {
    return await publicAxios.post(`/api/verify-frontend-password`, { password })
}
