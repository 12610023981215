import React, { useCallback, useEffect } from "react"
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { Button, Image } from "react-bootstrap"

import { ScrollToTop } from "@components/ScrollToTop"
import { LoadingState } from "@components/LoadingState"

import { Header } from "@components/Header"

import ErrorImage from "@assets/illustrations/error/page-not-found.svg"
import { useAuth } from "./context"
import { useIdleTimer } from "react-idle-timer"
import { toMilliseconds } from "./helpers/GlobalFunctions"

const TIME_OUT = toMilliseconds(0, 5, 0);
const App = () => {
    const { routes, isLoading, isLogin, tokenExpired } = useAuth()
    const { pathname } = useLocation()

    
    const {
        start,
        pause,
    } = useIdleTimer({
        crossTab: true,
        onAction: () => {
            localStorage.setItem(
                "_expiredTime",
                Date.now() + TIME_OUT
            )
        },
        onIdle: () => {
            console.log('SESSION_EXPIRED');
            tokenExpired()
        },
        stopOnIdle: true,
        timeout: TIME_OUT,
        startOnMount: false,
        startManually: true,
        leaderElection: true,
        syncTimers: 200
    })

    const cleanupTimer = useCallback(() => {
        const eTimer = localStorage.getItem("_expiredTime");
        if (eTimer) {
            const expiredTime = parseInt(
                localStorage.getItem("_expiredTime") || 0,
                10
            )

            if (expiredTime < Date.now()) {
                return true
            }

            return false
        }
    }, [])

    useEffect(() => {
        if (isLogin) {
            if (cleanupTimer()) {
                tokenExpired()
                return;
            }
            console.log('SESSION_INITIATED');
            start();
            return;
        }
    }, [isLogin, start, cleanupTimer, tokenExpired])

    useEffect(() => {
        if (!isLoading && !isLogin) {
            localStorage.removeItem("_expiredTime");
            pause();
        }
    }, [isLogin, isLoading, cleanupTimer, pause])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingState />
            ) : (
                <React.Suspense fallback={<LoadingState />}>
                    {routes?.length > 0 && (
                        <Routes>
                            <React.Fragment>
                                {
                                    RenderRoutes(routes)
                                }

                                {routes[0].link && (
                                    <Route
                                        path="/"
                                        element={
                                            <Navigate
                                                to={routes[0].link}
                                                replace
                                            />
                                        }
                                    />
                                )}
                                <Route path="*" element={<Error />} />
                            </React.Fragment>
                        </Routes>
                    )}
                    <ScrollToTop />
                </React.Suspense>
            )}
        </React.Fragment>
    )
}

const RenderRoutes = (routes) => {
    return routes.map((route, indx) => {
        const RouteComponent = route.component
        return route.link && RouteComponent ? (
            <Route
                key={indx}
                path={route.link}
                element={ComponentWrapper(RouteComponent)}
            />
        ) : (
            RenderRoutes(route.children)
        )
    })
}

const ComponentWrapper = (WrappingComponent) => {
    const WrapComponent = () => {
        return (
            <React.Fragment>
                <Header />
                <WrappingComponent />
            </React.Fragment>
        )
    }

    return <WrapComponent />
}

const Error = () => {
    return (
        <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center py-5">
            <div className="text-center">
                <div>
                    <h1 className="mb-1 fs-4 fw-bold">Page Not Found 🕵🏻‍♀️</h1>
                    <p className="mb-2 small">
                        Oops! 😖 The requested URL was not found on this server.
                    </p>
                    <Button as={Link} to="/" className="mt-3">
                        Back to home
                    </Button>
                </div>
                <Image fluid src={ErrorImage} className="my-5" />
            </div>
        </div>
    )
}

export default App
