import { convertFromRaw } from "draft-js"
import { PhoneNumberUtil } from 'google-libphonenumber';
import * as Yup from 'yup';

export const filterRecord = (object, key, value) => {
    if (Array.isArray(object)) {
        for (const obj of object) {
            const result = filterRecord(obj, key, value)
            if (result) {
                return obj
            }
        }
    } else {
        if (object.hasOwnProperty(key) && object[key] === value) {
            return object
        }

        for (const k of Object.keys(object)) {
            if (typeof object[k] === "object") {
                const o = filterRecord(object[k], key, value)
                if (o !== null && typeof o !== "undefined") return o
            }
        }

        return null
    }
}

const getErrorMessage = (data, error) => {
    Object.keys(data).forEach(function (key) {
        if (typeof data[key] === "object") {
            getErrorMessage(data[key], error)
        }

        // error += key + ": " + data[key] + "\n";
        if (key !== "err_code") {
            error += data[key] + "\n";
        }
    });

    return error
}

export const toMilliseconds = (hrs, min, sec) => (+hrs * 60 * 60 + +min * 60 + +sec) * 1000;

export const ErrorHandling = (error) => {
    let APIError =
        "Something went wrong, unable to initiate your request! Please check console for more information"
    if (error?.response) {
        const data = error?.response?.data
        const status = error?.response?.status
        const fallbackError = "Something went wrong while processing your request.\nPlease check console for more detail";
        if (!isEmpty(data)) {

            if (isJson(data)) {
                let errorMessage = "";
                errorMessage = getErrorMessage(data, errorMessage)

                if (errorMessage && errorMessage !== "") {
                    APIError = errorMessage;
                } else {
                    APIError = fallbackError
                }

            } else if (typeof data === "string") {
                APIError = data;
            } else {
                APIError = fallbackError;
            }

            console.error(data || APIError, "Status Code: " + status)

        } else {
            const jsonError = error.toJSON()
            if (jsonError) {
                APIError = jsonError.message || APIError
            } else {
                APIError = fallbackError
            }
        }

    } else if (error?.request) {
        APIError = error?.request?.responseText || error?.response || "Request Failed! Check your Network Connection"
        console.error("No Response Data Received", APIError)
    } else {
        APIError = error?.error || error?.message || "Unable to process the request!"
        console.error("Request Error", APIError)
    }

    return APIError
}

export const isJson = (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item

    try {
        item = JSON.parse(item)
    } catch (e) {
        return false
    }

    if (typeof item === "object" && item !== null) {
        return true
    }

    return false
}

export const validateNumber = (event) => {
    const keyCode = event.keyCode
    const ctrlKeyCode = event.ctrlKey
    const metaKeyCode = event.metaKey
    const shiftKeyCode = event.shiftKey

    if (
        [46, 8, 9, 27, 13].indexOf(keyCode) !== -1 ||
        //Allow plus character
        (shiftKeyCode && keyCode === 187) ||
        keyCode === 107 ||
        // Allow: Ctrl+A
        (keyCode === 65 && (ctrlKeyCode || metaKeyCode)) ||
        // Allow: Ctrl+C
        (keyCode === 67 && (ctrlKeyCode || metaKeyCode)) ||
        // Allow: Ctrl+V
        (keyCode === 86 && (ctrlKeyCode || metaKeyCode)) ||
        // Allow: Ctrl+X
        (keyCode === 88 && (ctrlKeyCode || metaKeyCode)) ||
        // Allow: home, end, left, right
        (keyCode >= 35 && keyCode <= 39)
    ) {
        // let it happen, don't do anything
        return
    }

    // Ensure that it is a number and stop the keypress
    if (
        (shiftKeyCode || keyCode < 48 || keyCode > 57) &&
        (keyCode < 96 || keyCode > 105)
    ) {
        event.preventDefault()
    }
}

export const isEmpty = (obj) => {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false
    }

    return true
}

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result)
        }
        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

export const isArray = (a) => {
    return !!a && a.constructor === Array
}

export const isObject = (a) => {
    return !!a && a.constructor === Object
}

export const groupBy = (arr = [], property) => {
    if (property && arr.length) {
        return arr.reduce((r, a) => {
            let key = a[property]

            if (key) {
                key = key.toUpperCase()
            }
            r[key] = r[key] || []
            r[key].push(a)
            return r
        }, {})
    }

    return null
}

export const groupByRichEditor = (arr = [], key) => {
    if (key && arr.length) {
        return arr.reduce((r, a) => {
            let curr = undefined
            if (isJson(a[key])) {
                curr = convertFromRaw(JSON.parse(a[key]))
                    .getPlainText("\u0001")
                    .toUpperCase()
            }

            r[curr] = r[curr] || []
            r[curr].push(a)
            return r
        }, {})
    }

    return null
}

export const groupArrayOfObjects = (array, property, textEditorVal = false) => {
    function containsKey(object, key) {
        return Object.keys(object).find(k => {
            if (textEditorVal && isJson(k)) {
                k = convertFromRaw(JSON.parse(k)).getPlainText("\u0001").toUpperCase()
                return k.toLowerCase().trim() === key.toLowerCase().trim()
            } else {
                return k.toLowerCase().trim() === key.toLowerCase().trim()
            }
        });
    }

    var group_to_values = array.reduce(function (obj, item) {
        let key = item[property];
        if (key) {
            if (textEditorVal && isJson(key)) {
                key = convertFromRaw(JSON.parse(key)).getPlainText("\u0001").toUpperCase()
            }

            const duplicateKey = containsKey(obj, key)
            if (duplicateKey) {
                key = duplicateKey;
            }
        }

        obj[key] = obj[key] || []
        obj[key].push(item)
        return obj
    }, {})

    var groups = Object.keys(group_to_values).map(function (key) {
        if (key !== "null" && key !== "undefined") {
            return { property: key, data: group_to_values[key] }
        } else {
            return { property: "Media", data: group_to_values[key] }
        }
    })

    return groups
}


export const getUserAvatar = (letters, flip = false, size) => {
    let canvas = document.createElement("canvas")
    let context = canvas.getContext("2d")
    size = size || 200

    const charCodeRed = letters.charCodeAt(0);
    const charCodeGreen = letters.charCodeAt(1) || charCodeRed;

    const red = Math.pow(charCodeRed, 7) % 200;
    const green = Math.pow(charCodeGreen, 7) % 200;
    const blue = (red + green) % 200;


    canvas.width = size
    canvas.height = size

    context.font = "bold " + Math.round(size / 3) + "px Titillium Web, Arial, sans-serif"
    context.textAlign = "center"

    if (flip) {
        context.fillStyle = `rgb(255, 255, 255)`
        context.fillRect(0, 0, size, size)
        context.fillStyle = `rgb(${red}, ${green}, ${blue})`;
        context.fillText(letters, size / 2, size / 1.65)
    } else {
        context.fillStyle = `rgba(${red}, ${green}, ${blue}, ` + 0.1 + `)`
        context.fillRect(0, 0, size, size)
        context.fillStyle = `rgb(${red}, ${green}, ${blue})`;
        context.fillText(letters, size / 2, size / 1.65)
    }

    // Set image representation in default format (png)
    const dataURI = canvas.toDataURL()
    // Dispose canvas element
    canvas = null
    return dataURI
}

const phoneUtil = PhoneNumberUtil.getInstance();
export const validatePhone = (phone) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

export const yupPhoneInput = Yup.string()
    .trim()
    .transform((v, o) => (o === '' ? null : v))
    .test('phone_number', 'enter a valid phone number starting with country code.', (value, context) => {
        let isRequired = false;
        const schema = context.schema;
        if ('exclusiveTests' in schema && 'required' in schema.exclusiveTests) {
            if (schema.exclusiveTests.required) {
                isRequired = true;
            }
        }
        return (isRequired && value) || (value && value.length > 0) ? validatePhone(value) : true;
    });
